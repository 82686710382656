import { ReactNode } from "react";
import { Button, Table, Tag, Tooltip } from "antd";
import {
  CheckOutlined,
  EditOutlined,
  LinkOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { formatDate } from "~utils/dateHandling";
import { PublishStatusTitleEnum } from "~enums/publishStatus.enum";
import { ColorEnum } from "~enums/color.enum";
import { UrlEnum } from "~enums/url.enum";
import { DataQaSelectorEnum } from "~enums/dataQaSelector.enum";
import {
  AdManagementOverviewItem,
  Maybe,
  PublishState,
  PublishStatus,
  RetailAdStatus,
} from "~gql/retail-ad-management-overview-types";
import { useNavigateTo } from "~hooks/useNavigateTo";

const styles = {
  checkOutlined: { color: ColorEnum.green },
  editButton: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
  },
};

const { Column } = Table;

const dataQaSelectorWrapper = (content: ReactNode, dataQaSelector?: string) => {
  return <span data-qa-selector={dataQaSelector}>{content}</span>;
};

export const renderOptionalText = (value: string, dataQaSelector?: string) =>
  dataQaSelectorWrapper(value || <MinusOutlined />, dataQaSelector);

export const renderLink = (
  stockNumber: string,
  adId: string,
  dataQaSelector?: string
) =>
  dataQaSelectorWrapper(
    <a href={`${UrlEnum.retailAd}/${adId}`}>{stockNumber}</a>,
    dataQaSelector
  );

export const renderDate = (value: string, dataQaSelector?: string) =>
  dataQaSelectorWrapper(
    value ? formatDate(value) : <MinusOutlined />,
    dataQaSelector
  );

export const renderBoolean = (value: boolean, dataQaSelector?: string) =>
  dataQaSelectorWrapper(
    value ? (
      <CheckOutlined
        style={styles.checkOutlined}
        data-qa-selector={DataQaSelectorEnum.check}
      />
    ) : (
      <MinusOutlined data-qa-selector={DataQaSelectorEnum.minus} />
    ),
    dataQaSelector
  );

interface Status {
  id: string;
  label: string;
}

export const renderStatus = (
  status: string,
  statuses: Status[],
  dataQaSelector?: string
) => {
  const content = status ? (
    <Tag color={ColorEnum.black}>
      {statuses.find(({ id }) => id === status)?.label ?? status}
    </Tag>
  ) : (
    <MinusOutlined />
  );
  return dataQaSelectorWrapper(content, dataQaSelector);
};

const getColorByStatus = (status: PublishStatus) => {
  switch (status) {
    case PublishStatus.Published:
      return ColorEnum.black;
    case PublishStatus.Unpublished:
      return ColorEnum.gray;
    default:
      return ColorEnum.warning;
  }
};

export const renderMarketplaceWithLink = (
  name: string,
  statusInfo?: Maybe<PublishState>,
  dataQaSelector?: string
) => {
  if (!statusInfo) {
    return null;
  }

  const capitalizedLabel = PublishStatusTitleEnum[statusInfo.label];

  if (statusInfo.label === PublishStatus.Error) {
    return dataQaSelectorWrapper(
      <Tooltip placement="top" title={capitalizedLabel}>
        <Tag
          color={ColorEnum.red}
          data-qa-selector={DataQaSelectorEnum.tagError}
        >
          {name}
        </Tag>
      </Tooltip>,
      dataQaSelector
    );
  }

  if (statusInfo.link && name !== Marketplaces.MOBILE_DE) {
    return dataQaSelectorWrapper(
      <Tooltip placement="top" title={capitalizedLabel}>
        <Tag color={getColorByStatus(statusInfo.label)}>
          <a
            style={styles.link}
            href={statusInfo.link}
            target="_blank"
            data-qa-selector={DataQaSelectorEnum.statusWithLink}
          >
            {name} <LinkOutlined />
          </a>
        </Tag>
      </Tooltip>,
      dataQaSelector
    );
  }

  return dataQaSelectorWrapper(
    <Tooltip placement="top" title={capitalizedLabel}>
      <Tag color={getColorByStatus(statusInfo.label)}>{name}</Tag>
    </Tooltip>,
    dataQaSelector
  );
};

export const renderPublishStatusWithLink = (
  statusInfo: PublishState,
  dataQaSelector?: string
) => {
  if (!statusInfo) {
    return dataQaSelectorWrapper(<MinusOutlined />, dataQaSelector);
  }

  const capitalizedLabel = PublishStatusTitleEnum[statusInfo.label];

  if (statusInfo.label === PublishStatus.Error) {
    return dataQaSelectorWrapper(
      <Tag color={ColorEnum.red}>{capitalizedLabel}</Tag>,
      dataQaSelector
    );
  }

  if (statusInfo.link) {
    return dataQaSelectorWrapper(
      <Tag color={ColorEnum.black}>
        <a style={styles.link} href={statusInfo.link} target="_blank">
          {capitalizedLabel} <LinkOutlined />
        </a>
      </Tag>,
      dataQaSelector
    );
  }

  return dataQaSelectorWrapper(
    <Tag color={ColorEnum.black}>{capitalizedLabel}</Tag>,
    dataQaSelector
  );
};

export const ActionButton = ({ id }: { id: string }) => {
  const navigateTo = useNavigateTo();
  return (
    <Button
      icon={<EditOutlined />}
      type="primary"
      style={styles.editButton}
      href={`${UrlEnum.ad}/${id}`}
      data-qa-selector={DataQaSelectorEnum.editButton}
      onClick={navigateTo}
    />
  );
};

export const renderContentTextWithDataQaSelector = (
  value: string,
  dataQaSelector: string
) => <span data-qa-selector={dataQaSelector}>{value}</span>;

interface Props {
  data: AdManagementOverviewItem[];
  loading?: boolean;
  canQueryAdMgmt?: boolean;
  offset: number;
  retailStatuses?: RetailAdStatus[];
}

const enum Marketplaces {
  MOBILE_DE = "Mobile.de",
  AS24 = "AS24",
}

export function renderClassifieds(data: AdManagementOverviewItem) {
  const mobileDeStatus = renderMarketplaceWithLink(
    Marketplaces.MOBILE_DE,
    data?.mobileDeStatus,
    DataQaSelectorEnum.mobileDeStatus
  );
  const as24Status = renderMarketplaceWithLink(
    Marketplaces.AS24,
    data?.as24Status,
    DataQaSelectorEnum.as24Status
  );

  return (
    <div>
      {mobileDeStatus}
      {as24Status}
      {!mobileDeStatus &&
        !as24Status &&
        dataQaSelectorWrapper(<MinusOutlined />, DataQaSelectorEnum.minus)}
    </div>
  );
}

function DataTable({
  data,
  loading = false,
  canQueryAdMgmt = false,
  offset,
  retailStatuses = [],
}: Props) {
  const indexShiftNumber = 1;

  return (
    <Table
      dataSource={data}
      rowKey="stockNumber"
      pagination={false}
      loading={loading}
      scroll={{
        x: true,
      }}
      size="small"
    >
      <Column
        title="#"
        render={(_, __, index) =>
          dataQaSelectorWrapper(
            <>{offset + index + indexShiftNumber}</>,
            DataQaSelectorEnum.index
          )
        }
      />
      <Column
        dataIndex="vin"
        key="vin"
        title="VIN"
        render={(data) => dataQaSelectorWrapper(data, DataQaSelectorEnum.vin)}
      />
      <Column
        dataIndex="stockNumber"
        key="stockNumber"
        title="StockNo."
        render={(data) =>
          dataQaSelectorWrapper(data, DataQaSelectorEnum.stockNumber)
        }
      />
      <Column
        dataIndex="countryCode"
        key="countryCode"
        title="Country"
        render={(data) =>
          dataQaSelectorWrapper(data, DataQaSelectorEnum.countryCode)
        }
      />
      <Column
        dataIndex="vehicle"
        key="vehicle"
        title="Vehicle"
        render={(data) =>
          dataQaSelectorWrapper(data, DataQaSelectorEnum.vehicle)
        }
      />
      <Column
        title="First Published"
        render={(data) =>
          renderDate(data.firstPublishedAt, DataQaSelectorEnum.firstPublishedAt)
        }
      />
      <Column
        title="Online days"
        render={(data) =>
          renderOptionalText(data.daysOnline, DataQaSelectorEnum.daysOnline)
        }
      />
      <Column
        title="Has images"
        render={(data) =>
          renderBoolean(data.hasImages, DataQaSelectorEnum.hasImages)
        }
      />
      <Column
        title="Status"
        render={(data) =>
          renderStatus(
            data.retailStatus,
            retailStatuses,
            DataQaSelectorEnum.retailStatus
          )
        }
      />
      <Column
        title="Autohero"
        render={(data) =>
          renderPublishStatusWithLink(
            data.autoheroStatus,
            DataQaSelectorEnum.autoheroStatus
          )
        }
      />
      <Column title="Classifieds" render={(data) => renderClassifieds(data)} />
      {canQueryAdMgmt && (
        <Column title="" render={(data) => <ActionButton id={data.adId} />} />
      )}
    </Table>
  );
}

export default DataTable;
