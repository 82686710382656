import { CSSProperties, useEffect } from "react";
import { Card, Select, Input, Space, Button, Form, Tabs } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Label from "./Label";
import { BaseOptionType } from "antd/es/select";
import { DataQaSelectorEnum } from "~enums/dataQaSelector.enum";
import { publishedOptions } from "~constants/filters";
import { PublishedStatusEnum } from "~enums/publishStatus.enum";
import { RetailAdStatus } from "~gql/retail-ad-management-overview-types";
import { RetailStatusEnum } from "~enums/retailStatus.enum";

const { Option } = Select;
const { useForm } = Form;
const { TabPane } = Tabs;

const styles = {
  card: { width: "100%" },
  minWidth: {
    minWidth: 170,
  },
  checkbox: { marginBottom: 8, userSelect: "none" } as CSSProperties,
  filterWrapper: {
    width: "100%",
    border: "1px solid #f0f0f0",
    borderTop: "none",
    marginTop: "-17px",
    padding: "17px 0 8px 8px",
  },
};

export type State = {
  retailStatus: string | undefined;
  retailCountry: string | undefined;
  searchText: string | undefined;
  published: PublishedStatusEnum | undefined;
};
export enum Placeholders {
  retailStatus = "Select Retail Status",
  retailCountry = "Select Country",
  searchText = "VIN or StockNumber",
  published = "Is Published?",
}

const initialState: State = {
  retailStatus: RetailStatusEnum.importedToRetail,
  retailCountry: undefined,
  searchText: undefined,
  published: undefined,
};

interface Props {
  initialValues?: Readonly<State>;
  handleSubmit?: (state: State) => void;
  countries?: Readonly<Array<{ label: string; value: string }>>;
  retailStatuses?: Readonly<RetailAdStatus[]>;
}

export const filterOption = (input: string, option?: BaseOptionType): boolean =>
  option?.["title"]?.toLowerCase().includes(input.toLowerCase()) || false;

function Filters({
  initialValues = initialState,
  handleSubmit = () => {},
  countries = [],
  retailStatuses = [],
}: Props) {
  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [initialValues, form]);

  return (
    <Card style={styles.card}>
      <Form
        name="inventory-overview-search"
        form={form}
        initialValues={initialValues}
        onFinish={(values: State) => handleSubmit(values)}
      >
        <Form.Item name="retailStatus" noStyle={true} valuePropName="activeKey">
          <Tabs
            type="card"
            data-qa-selector={DataQaSelectorEnum.retailAdState}
            onChange={form.submit}
          >
            {retailStatuses.map(({ id, label }) => (
              <TabPane
                key={id}
                tab={<span data-qa-selector-value={id}>{label}</span>}
              />
            ))}
          </Tabs>
        </Form.Item>
        <Space align="end" style={styles.filterWrapper}>
          <Label text="VIN or StockNumber:">
            <Form.Item name="searchText" noStyle={true}>
              <Input
                size="large"
                placeholder={Placeholders.searchText}
                style={styles.minWidth}
                name="searchText"
                data-qa-selector={DataQaSelectorEnum.searchText}
              />
            </Form.Item>
          </Label>
          <Label text="Is Published?">
            <Form.Item name="published" noStyle={true}>
              <Select
                style={styles.minWidth}
                size="large"
                showSearch={true}
                placeholder={Placeholders.published}
                allowClear={true}
                filterOption={filterOption}
                data-qa-selector={DataQaSelectorEnum.isPublished}
              >
                {publishedOptions.map(({ value, label }) => (
                  <Option
                    title={label}
                    value={value}
                    key={value}
                    data-qa-selector={DataQaSelectorEnum.option}
                    data-qa-selector-value={value}
                  >
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Label>
          <Label text="Country:">
            <Form.Item name="retailCountry" noStyle={true}>
              <Select
                style={styles.minWidth}
                size="large"
                showSearch={true}
                placeholder={Placeholders.retailCountry}
                allowClear={true}
                data-qa-selector={DataQaSelectorEnum.select}
                data-qa-selector-name={DataQaSelectorEnum.country}
                filterOption={filterOption}
              >
                {countries.map(({ value, label }) => (
                  <Option
                    title={label}
                    value={value}
                    key={value}
                    data-qa-selector={DataQaSelectorEnum.option}
                    data-qa-selector-option-value={value}
                  >
                    {label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Label>
          <Button
            icon={<SearchOutlined />}
            size="large"
            type="primary"
            htmlType="submit"
            data-qa-selector={DataQaSelectorEnum.searchButton}
          />
        </Space>
      </Form>
    </Card>
  );
}

export default Filters;
