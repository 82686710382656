import { PublishedStatusEnum } from "~enums/publishStatus.enum";

export const publishedOptions: Readonly<
  Array<{ label: string; value: PublishedStatusEnum }>
> = [
  {
    label: "Published",
    value: PublishedStatusEnum.PUBLISHED,
  },
  {
    label: "Unpublished",
    value: PublishedStatusEnum.UNPUBLISHED,
  },
];
