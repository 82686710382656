import { AdMgmtOverviewError, ApiError } from "~src/types";

export default class ResponseError extends Error {
  private static errorName = "ResponseError";

  /* istanbul ignore next */
  public constructor(public readonly errors: ApiError[] = []) {
    super();
    Object.setPrototypeOf(this, ResponseError.prototype);
    this.name = ResponseError.errorName;
  }

  public format = (): AdMgmtOverviewError => ({
    title: "Server Error",
    description: this.errors.map(({ message }) => message),
  });
}
