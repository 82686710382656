// THIS FILE IS GENERATED, DO NOT EDIT!

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export const enum PublishStatus {
  Published = "PUBLISHED",
  Unpublished = "UNPUBLISHED",
  Error = "ERROR",
}

export type AdManagementOverviewConstants = {
  __typename?: "AdManagementOverviewConstants";
  retailStatuses: Array<RetailAdStatus>;
  countries: Array<Scalars["String"]>;
};

export type AdManagementOverviewInput = {
  filter?: InputMaybe<Filter>;
  pager?: InputMaybe<Pager>;
};

export type Filter = {
  searchText?: InputMaybe<Scalars["String"]>;
  retailStatus?: InputMaybe<Scalars["String"]>;
  retailCountry?: InputMaybe<Scalars["String"]>;
  published?: InputMaybe<Scalars["Boolean"]>;
};

export type Pager = {
  page: Scalars["Int"];
  pageSize: Scalars["Int"];
};

export type AdManagementOverviewPage = {
  __typename?: "AdManagementOverviewPage";
  items: Array<AdManagementOverviewItem>;
  total: Scalars["Int"];
  canQueryAdMgmt: Scalars["Boolean"];
};

export type AdManagementOverviewItem = {
  __typename?: "AdManagementOverviewItem";
  adId: Scalars["String"];
  countryCode: Scalars["String"];
  stockNumber: Scalars["String"];
  vin: Scalars["String"];
  vehicle: Scalars["String"];
  firstPublishedAt?: Maybe<Scalars["String"]>;
  daysOnline?: Maybe<Scalars["Int"]>;
  hasImages: Scalars["Boolean"];
  retailStatus: Scalars["String"];
  wkdaStatus?: Maybe<PublishState>;
  autoheroStatus?: Maybe<PublishState>;
  mobileDeStatus?: Maybe<PublishState>;
  as24Status?: Maybe<PublishState>;
};

export type PublishState = {
  __typename?: "PublishState";
  label: PublishStatus;
  link?: Maybe<Scalars["String"]>;
};

export type RetailAdStatus = {
  __typename?: "RetailAdStatus";
  id: Scalars["String"];
  label: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  adManagementOverviewPage: AdManagementOverviewPage;
  adManagementOverviewConstants: AdManagementOverviewConstants;
};

export type QueryAdManagementOverviewPageArgs = {
  input?: InputMaybe<AdManagementOverviewInput>;
};

export type AdManagementOverviewPageQueryVariables = Exact<{
  input?: InputMaybe<AdManagementOverviewInput>;
}>;

export type AdManagementOverviewPageQuery = {
  __typename?: "Query";
  adManagementOverviewPage: {
    __typename?: "AdManagementOverviewPage";
    total: number;
    canQueryAdMgmt: boolean;
    items: Array<{
      __typename?: "AdManagementOverviewItem";
      adId: string;
      vin: string;
      stockNumber: string;
      countryCode: string;
      vehicle: string;
      firstPublishedAt?: string | null;
      daysOnline?: number | null;
      hasImages: boolean;
      retailStatus: string;
      autoheroStatus?: {
        __typename?: "PublishState";
        label: PublishStatus;
        link?: string | null;
      } | null;
      mobileDeStatus?: {
        __typename?: "PublishState";
        label: PublishStatus;
        link?: string | null;
      } | null;
      as24Status?: {
        __typename?: "PublishState";
        label: PublishStatus;
        link?: string | null;
      } | null;
    }>;
  };
};

export type AdManagementOverviewConstantsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AdManagementOverviewConstantsQuery = {
  __typename?: "Query";
  adManagementOverviewConstants: {
    __typename?: "AdManagementOverviewConstants";
    countries: Array<string>;
    retailStatuses: Array<{
      __typename?: "RetailAdStatus";
      id: string;
      label: string;
    }>;
  };
};

export declare const adManagementOverviewPage: import("graphql").DocumentNode;
export declare const adManagementOverviewConstants: import("graphql").DocumentNode;

export const AdManagementOverviewPageDocument = `
    query adManagementOverviewPage($input: AdManagementOverviewInput) {
  adManagementOverviewPage(input: $input) {
    items {
      adId
      vin
      stockNumber
      countryCode
      vehicle
      firstPublishedAt
      daysOnline
      hasImages
      retailStatus
      autoheroStatus {
        label
        link
      }
      mobileDeStatus {
        label
        link
      }
      as24Status {
        label
        link
      }
    }
    total
    canQueryAdMgmt
  }
}
    `;
export const AdManagementOverviewConstantsDocument = `
    query adManagementOverviewConstants {
  adManagementOverviewConstants {
    retailStatuses {
      id
      label
    }
    countries
  }
}
    `;
export type Requester<C = {}> = <R, V>(
  doc: string,
  vars?: V,
  options?: C
) => Promise<R>;
export function getSdk<C>(requester: Requester<C>) {
  return {
    adManagementOverviewPage(
      variables?: AdManagementOverviewPageQueryVariables,
      options?: C
    ): Promise<AdManagementOverviewPageQuery> {
      return requester<
        AdManagementOverviewPageQuery,
        AdManagementOverviewPageQueryVariables
      >(AdManagementOverviewPageDocument, variables, options);
    },
    adManagementOverviewConstants(
      variables?: AdManagementOverviewConstantsQueryVariables,
      options?: C
    ): Promise<AdManagementOverviewConstantsQuery> {
      return requester<
        AdManagementOverviewConstantsQuery,
        AdManagementOverviewConstantsQueryVariables
      >(AdManagementOverviewConstantsDocument, variables, options);
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;
