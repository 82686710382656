import { createStore } from "effector";
import { equals } from "ramda";
import { FilterParams } from "./types";
import { DEFAULT_PAGE, PAGE_SIZE } from "./constants";
import { RetailStatusEnum } from "~enums/retailStatus.enum";

export const initialState: FilterParams = {
  page: DEFAULT_PAGE,
  pageSize: PAGE_SIZE,
  retailStatus: RetailStatusEnum.importedToRetail,
};

const $filterParamsStore = createStore<FilterParams>(initialState, {
  updateFilter: (update, current) => !equals(update, current),
});

export default $filterParamsStore;
