import { ApiError } from "~src/types";
import ResponseError from "~utils/ResponseError";
import callApi, { METHODS } from "./callApi";

interface Response<T> {
  data: T;
  errors: ApiError[];
}

const callGql =
  (url: string) =>
  async <T extends unknown>(
    query: string,
    variables?: any,
    mockedResult?: any
  ): Promise<T> => {
    if (mockedResult?.errors?.length > 0) {
      throw new ResponseError(mockedResult.errors);
    }
    if (mockedResult) {
      return Promise.resolve(mockedResult);
    }
    const response = await callApi<Response<T>>(METHODS.POST, url, {
      query,
      variables,
    });
    if (response?.errors?.length > 0) {
      throw new ResponseError(response.errors);
    }
    return response.data;
  };

export default callGql;
