import localizedCountries from "localized-countries/data/en_GB.json";
import { combine } from "effector";
import $countriesStore from "./countries.store";
import $retailStatusesStore from "./retailStatuses.store";
import { propOr, sortBy, prop, pipe, map } from "ramda";
import { RetailStatusAll } from "~constants/retailStatusAll";

export const $filtersDictionaries = combine({
  retailStatuses: $retailStatusesStore.map((items) => [
    ...items,
    RetailStatusAll,
  ]),
  countries: $countriesStore.map(
    pipe(
      map((code) => ({
        label: propOr<string, typeof localizedCountries, string>(
          code,
          code,
          localizedCountries
        ),
        value: code,
      })),
      sortBy(prop("label"))
    )
  ),
});
