import $countriesStore from "./countries.store";
import $retailStatuesStore from "./retailStatuses.store";
import { getDictionariesFX } from "./effects";
import gate from "./gate";
import { forward } from "effector";

// Map event to effect
forward({
  from: gate.open,
  to: getDictionariesFX,
});

// Store data
$countriesStore.on(
  getDictionariesFX.doneData,
  (_, payload) => payload?.countries
);
$retailStatuesStore.on(
  getDictionariesFX.doneData,
  (_, payload) => payload?.retailStatuses
);

// Catch errors

export const errors = getDictionariesFX.fail.map(({ error }) => error);
