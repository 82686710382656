import { createEffect } from "effector";
import { stringify } from "query-string";
import { FilterParams } from "./types";
import { DEFAULT_PAGE, PAGE_SIZE } from "./constants";
import { PublishedStatusEnum } from "~enums/publishStatus.enum";
import { RetailStatusEnum } from "~enums/retailStatus.enum";
import { AdManagementOverviewInput } from "~gql/retail-ad-management-overview-types";
import { adManagementOverviewSdk } from "~utils/sdk";

export const getAdMgmtOverviewsFX = createEffect(
  async (params: AdManagementOverviewInput) => {
    const data = await adManagementOverviewSdk.adManagementOverviewPage({
      input: params,
    });

    return data.adManagementOverviewPage;
  }
);

export const applyFilterParamsFX = createEffect(
  async (filterParams: FilterParams) => {
    const params = Object.entries(filterParams).reduce((acc, [key, value]) => {
      if (value !== null && value !== "") {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, string | number>);
    const queryString = stringify(params);
    if (queryString !== location.search.slice(1)) {
      history.pushState(
        {
          userEvent: true,
        },
        "",
        `${location.pathname}?${queryString}`
      );
    }
  }
);

// Map query params to filter params
type ParamValue = string | string[] | null | undefined;
export function mapToFilterParams<T extends Record<string, ParamValue>>(
  params: T
): FilterParams {
  const getValue = (value: ParamValue): string | undefined =>
    (Array.isArray(value) ? value.pop() : value) ?? undefined;
  const publishedRaw = getValue(params["published"]);
  let published;
  if (publishedRaw === PublishedStatusEnum.PUBLISHED) {
    published = PublishedStatusEnum.PUBLISHED;
  } else if (publishedRaw === PublishedStatusEnum.UNPUBLISHED) {
    published = PublishedStatusEnum.UNPUBLISHED;
  }

  return {
    retailStatus:
      getValue(params["retailStatus"]) ?? RetailStatusEnum.importedToRetail,
    retailCountry: getValue(params["retailCountry"]),
    searchText: getValue(params["searchText"]),
    page: Number(getValue(params["page"]) ?? DEFAULT_PAGE),
    pageSize: Number(getValue(params["pageSize"]) ?? PAGE_SIZE),
    published,
  };
}
