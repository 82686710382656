import { forward, merge } from "effector";
import { errorMessage } from "./errors/events";
import { errors as adMgmtOverviewErrors } from "./adMgmtOverview/init";
import { errors as dictionariesErrors } from "./dictionaries/init";
import "./errors/init";

forward({
  from: merge([adMgmtOverviewErrors, dictionariesErrors]),
  to: errorMessage,
});
