import { attach, forward, guard, merge } from "effector";
import { pick } from "ramda";
import {
  itemsReset,
  setFilters,
  setPage,
  setPageSize,
  setAllFilters,
} from "./events";
import { FilterParams } from "./types";
import gate from "./gate";
import $filterParamsStore from "./filterParams.store";
import $itemsStore from "./items.store";
import { applyFilterParamsFX, getAdMgmtOverviewsFX } from "./effects";
import $isLoadingStore from "./isLoading.store";
import { $queryInput } from "./projections";
import { DEFAULT_PAGE } from "./constants";
import filterSamePayload from "../../utils/samePayload";

const onPopState = gate.state.map((payload): FilterParams => {
  return {
    ...$filterParamsStore.defaultState,
    ...payload,
  };
});

forward({
  from: onPopState,
  to: setAllFilters,
});

// FilterParams
$filterParamsStore
  .on(setFilters, (state, payload) => ({
    ...state,
    ...payload,
    page: DEFAULT_PAGE,
  }))
  .on(setAllFilters, (_, payload) => payload)
  .on(setPage, (state, page) => ({
    ...state,
    page,
  }))
  .on(setPageSize, (state, pageSize) => ({
    ...state,
    pageSize,
  }));

// Items
$itemsStore
  .on(getAdMgmtOverviewsFX.doneData, (_, payload) => payload)
  .on(itemsReset, () => $itemsStore.defaultState);
// IsLoading
$isLoadingStore
  .on(getAdMgmtOverviewsFX, () => true)
  .on(getAdMgmtOverviewsFX.finally, () => false);

// Init data loading

const getAdMgmtOverviews = attach({
  source: $queryInput,
  effect: getAdMgmtOverviewsFX,
});

guard({
  clock: merge([setAllFilters, filterSamePayload(setFilters)]),
  source: $filterParamsStore,
  filter: (state) =>
    Object.values(
      pick(["retailStatus", "retailCountry", "searchText", "published"], state)
    ).some((value) => value !== null),
  target: getAdMgmtOverviews,
});

// Apply FilterParams
const applyFilterParams = attach({
  source: $filterParamsStore,
  effect: applyFilterParamsFX,
});
forward({
  from: merge([setPage, setPageSize, setFilters]),
  to: applyFilterParams,
});

// Catch errors

export const errors = merge([
  getAdMgmtOverviewsFX.fail.map(({ error }): Error => error),
]);
