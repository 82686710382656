export enum PublishedStatusEnum {
  PUBLISHED = "true",
  UNPUBLISHED = "false",
}

export enum PublishStatusTitleEnum {
  PUBLISHED = "Published",
  UNPUBLISHED = "Not published",
  ERROR = "Error",
}
