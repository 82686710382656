import { Space, Typography, Row, Col } from "antd";
import { useStore } from "effector-react";
import Filters from "~components/Filters";
import DataTable from "~components/DataTable";
import {
  setPage,
  setFilters,
  setPageSize,
} from "~models/adMgmtOverview/events";
import { $adMgmtOverviewData } from "~models/adMgmtOverview/projections";
import Gate from "~models/adMgmtOverview/gate";
import DictionariesGate from "~models/dictionaries/gate";
import useQueryParams from "~hooks/useQueryParams";
import { mapToFilterParams } from "~models/adMgmtOverview/effects";
import { $filtersDictionaries } from "~models/dictionaries/projections";
import { DataQaSelectorEnum } from "~enums/dataQaSelector.enum";
import { Pagination } from "~components/Pagination";

const styles = {
  wrapper: { padding: 20, maxWidth: "100%", width: "100%" },
};

function ListPage() {
  const {
    items,
    page,
    pageSize,
    totalItems,
    filters,
    isLoading,
    canQueryAdMgmt,
  } = useStore($adMgmtOverviewData);
  const offset = (page - 1) * pageSize;
  const { countries, retailStatuses } = useStore($filtersDictionaries);
  const queryParams = useQueryParams();

  /* istanbul ignore next */
  return (
    <Space direction="vertical" size="large" style={styles.wrapper}>
      <DictionariesGate />
      <Gate {...mapToFilterParams(queryParams ?? {})} />
      <Row align="middle">
        <Col xs={12}>
          <Typography.Title data-qa-selector={DataQaSelectorEnum.pageTitle}>
            AD MANAGEMENT
          </Typography.Title>
        </Col>
      </Row>
      <Filters
        initialValues={filters}
        handleSubmit={setFilters}
        countries={countries}
        retailStatuses={retailStatuses}
      />
      <Row align="middle" justify="space-between">
        <Col>
          <Typography.Title level={4}>
            {totalItems} total results
          </Typography.Title>
        </Col>
        <Col>
          <Pagination
            current={page}
            total={totalItems}
            pageSize={pageSize}
            onChange={setPage}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
          />
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <DataTable
            data={items}
            offset={offset}
            loading={isLoading}
            canQueryAdMgmt={canQueryAdMgmt}
          />
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Pagination
            current={page}
            total={totalItems}
            pageSize={pageSize}
            onChange={setPage}
            onShowSizeChange={(_, pageSize) => setPageSize(pageSize)}
          />
        </Col>
      </Row>
    </Space>
  );
}

export default ListPage;
