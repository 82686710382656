import getCookieByName from "./getCookieByName";

export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

const callApi = async <R extends unknown>(
  method: METHODS,
  url: string,
  body?: any
): Promise<R> => {
  const accessToken = getCookieByName("t");

  const headers = {
    "access-domain": "ad.management",
    accept: "application/json, text/plain, */*",
    "content-type": "application/json;charset=UTF-8",
    authorization: `Bearer ${accessToken}`,
  };

  const response = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(body),
  });

  return response.json();
};

export default callApi;
