import { Pagination as BasePagination, PaginationProps } from "antd";
import { DataQaSelectorEnum } from "~enums/dataQaSelector.enum";

export const Pagination = (props: PaginationProps) => (
  <BasePagination
    {...props}
    data-qa-selector={DataQaSelectorEnum.pagination}
    itemRender={(page, type, originalElement) => (
      <span
        data-qa-selector={DataQaSelectorEnum.paginationItem}
        data-qa-selector-page={type === "page" ? page : type}
      >
        {originalElement}
      </span>
    )}
  />
);
