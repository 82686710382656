import { createStore, guard, Event } from "effector";
import { equals } from "ramda";

export default function filterSamePayload<T>(event: Event<T>): Event<T | null> {
  const $store = createStore<{
    prev: T | null;
    next: T | null;
  }>({
    prev: null,
    next: null,
  });
  $store.on(event, ({ next: prev }, next) => {
    return {
      prev,
      next,
    };
  });

  return guard({
    source: $store,
    filter: ({ next, prev }) => equals(next, prev),
  }).map(({ next }) => next);
}
