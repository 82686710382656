import { createStore } from "effector";
import { AdManagementOverviewPage } from "~gql/retail-ad-management-overview-types";

export const initialState: AdManagementOverviewPage = {
  items: [],
  total: 0,
  canQueryAdMgmt: false,
};

const $itemsStore = createStore<AdManagementOverviewPage>(initialState);

export default $itemsStore;
