import { useContext, MouseEvent } from "react";
import { SingleSpaContext } from "single-spa-react";
import { navigateToUrl } from "single-spa";

export const useNavigateTo = () => {
  const { singleSpa } = useContext(SingleSpaContext);

  return (e: MouseEvent<HTMLAnchorElement>) => {
    (singleSpa.navigateToUrl as typeof navigateToUrl)(e);
  };
};
