import { ConfigProvider, notification } from "antd";
import ListPage from "~pages/ListPage";
import "~models/init";
import ErrorMessages from "~components/ErrorMessages";
import { ANTD_EXTERNAL_VERSION_LESS } from "~constants/antd";

notification.config({
  prefixCls: `${ANTD_EXTERNAL_VERSION_LESS}-notification`,
  top: 60,
  placement: "topRight",
});

export default function Root() {
  return (
    <ConfigProvider prefixCls={ANTD_EXTERNAL_VERSION_LESS}>
      <ErrorMessages />
      <ListPage />
    </ConfigProvider>
  );
}
