export enum DataQaSelectorEnum {
  pageTitle = "pageTitle",
  retailAdState = "retailAdState",
  country = "country",
  select = "select",
  option = "option",
  searchButton = "searchButton",
  switchBtn = "switch-btn",
  editButton = "editButton",
  index = "index",
  vin = "vin",
  stockNumber = "stockNumber",
  countryCode = "countryCode",
  vehicle = "vehicle",
  firstPublishedAt = "firstPublishedAt",
  daysOnline = "daysOnline",
  hasImages = "hasImages",
  retailStatus = "retailStatus",
  wkdaStatus = "wkdaStatus",
  autoheroStatus = "autoheroStatus",
  mobileDeStatus = "mobileDeStatus",
  as24Status = "AS24Status",
  errorDescription = "errorDescription",
  check = "check",
  minus = "minus",
  tagError = "tagError",
  statusWithLink = "statusWithLink",
  searchText = "searchText",
  isPublished = "isPublished",
  hasMobileDe = "hasMobileDe",
  pagination = "pagination",
  paginationItem = "paginationItem",
}
