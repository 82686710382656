import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./root.component";

if (__WEBPACK_DEV_SERVER__) {
  require("antd/dist/antd.less");
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  // @ts-ignore
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
