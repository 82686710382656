import { memo, useEffect } from "react";
import { Typography, notification } from "antd";
import { useStore } from "effector-react";
import $store from "~models/errors/store";
import { DataQaSelectorEnum } from "~enums/dataQaSelector.enum";

const { Paragraph } = Typography;

const ErrorMessages = memo(() => {
  const error = useStore($store);

  useEffect(() => {
    if (error) {
      notification.error({
        message: error.title,
        description: (
          <div
            style={{
              maxHeight: 400,
              overflowY: "scroll",
            }}
          >
            {error.description.map((item, index) => (
              <Paragraph
                key={index}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "more",
                }}
                data-qa-selector={DataQaSelectorEnum.errorDescription}
              >
                {item}
              </Paragraph>
            ))}
          </div>
        ),
        style: {
          width: 500,
        },
        duration: 0,
      });
    }
  }, [error]);

  return null;
});

export default ErrorMessages;
