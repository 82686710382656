import { combine } from "effector";
import $itemsStore from "./items.store";
import $filterParamsStore from "./filterParams.store";
import $isLoadingStore from "./isLoading.store";
import { PublishedStatusEnum } from "~enums/publishStatus.enum";
import { RetailStatusEnum } from "~enums/retailStatus.enum";
import { AdManagementOverviewInput } from "~gql/retail-ad-management-overview-types";

export const $adMgmtOverviewData = combine({
  items: $itemsStore.map(({ items }) => items),
  totalItems: $itemsStore.map(({ total }) => total),
  canQueryAdMgmt: $itemsStore.map(({ canQueryAdMgmt }) => canQueryAdMgmt),
  page: $filterParamsStore.map(({ page }) => page),
  pageSize: $filterParamsStore.map(({ pageSize }) => pageSize),
  filters: $filterParamsStore.map((state) => ({
    retailStatus: state.retailStatus,
    retailCountry: state.retailCountry,
    searchText: state.searchText,
    published: state.published,
  })),
  isLoading: $isLoadingStore,
});

/**
 * Map filters data for backend query
 */
export const $queryInput = $filterParamsStore.map(
  ({
    page,
    pageSize,
    published,
    retailStatus,
    searchText,
    retailCountry,
  }): AdManagementOverviewInput => ({
    pager: {
      page,
      pageSize,
    },
    filter: {
      retailStatus:
        retailStatus === RetailStatusEnum.all ? undefined : retailStatus, // Skip ALL
      retailCountry: retailCountry,
      searchText: searchText || undefined, // Skip empty string
      published: published
        ? published === PublishedStatusEnum.PUBLISHED
        : undefined, // Expected true or undefined
    },
  })
);
