import { useEffect, useState } from "react";
import { parse as queryStringParse } from "query-string";

type Params = Record<string, string | string[] | null> | undefined;

export const parse = (queryString: string) => {
  const params = Object.entries(queryStringParse(queryString)).reduce<Params>(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Array.isArray(value) ? value.map((item) => item ?? "") : value,
    }),
    {}
  );

  return params;
};

export default function useQueryParams() {
  const [state, setState] = useState<Params>(() =>
    parse(location.search.slice(1))
  );
  useEffect(() => {
    const handler = () => {
      const queryString = location.search.slice(1);
      setState(parse(queryString));
    };
    window.addEventListener("popstate", handler);
    return () => window.removeEventListener("popstate", handler);
  });

  return state;
}

// export default function useQueryParams() {
//   const [state, setState] = useState<
//     Record<string, string | string[] | null> | undefined
//   >(() => parse(location.search.slice(1)));
//   useEffect(() => {
//     const handler = () => {
//       const queryString = location.search.slice(1);
//       setState(parse(queryString));
//     };
//     window.addEventListener("popstate", handler);
//     return () => window.removeEventListener("popstate", handler);
//   });

//   return state;
// }
