import $store from "./store";
import { errorMessage } from "./events";
import ResponseError from "~utils/ResponseError";
import { logFX } from "./effects";
import { forward } from "effector";

forward({
  from: errorMessage,
  to: logFX,
});

$store.on(errorMessage, (_, error) => {
  if (error instanceof ResponseError) {
    return error.format();
  }

  return {
    title: "Error",
    description: [error.message],
  };
});
